<template>
  <CRow>
    <CModal
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
        :hide-footer="true"
    >
      <img alt="Registration Document" class="imageFormat" :src="viewImage" style="float: left;width: 100%"/>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.feedbacks.title }}</strong>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <span><b>Description : </b></span>{{ feedbackMessage }}
          </div>
          <hr>
          <b>Attachments</b>
          <CRow>
            <CCol sm="6" md="4" lg="12">
              <div class="form-group" id="people">
                <div class="verify-docs">
                  <div
                      v-for="(docs, index) in medias"
                      :key="docs.id"
                      class="doc-box"
                  >
                    <div class="card card-file media">
                      <div class="card-body text-center">
                        <div v-if="docs.type === 'image'">
                          <img
                              @click="open(docs.url)"
                              alt="Image"
                              :src="docs.url"
                              class="imageDimenstion"
                          />
                        </div>
                        <div v-else-if="docs.type === 'pdf'">
                          <img
                              :src="defaultPdfImage"
                              alt="Default Image"
                              v-on:click="viewPdf(docs.url)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                        <div v-else-if="docs.type === 'doc'">
                          <img
                              :src="defaultDocImage"
                              alt="Default Image"
                              v-on:click="viewDoc(docs.url)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                        <div v-else-if="docs.type === 'xls'">
                          <img
                              :src="defaultXlsImage"
                              alt="Default Image"
                              v-on:click="viewXls(docs.url)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                        <div v-else>
                          <img
                              :src="defaultDocImage"
                              alt="Default Image"
                              v-on:click="viewDoc(docs.url)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {feedbacks} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueSweetalert2 from "vue-sweetalert2";
import store from "../../store/store";
Vue.use(VueSweetalert2);
Vue.use(VueForm, options);

export default {
  name: "FeedbackView",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      messageColor: "success",
      feedbackMessage: "",
      toggleFilterValue: false /* for filter toggle*/,
      err_msg: "",
      viewImage: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      medias: [],
      module: feedbacks,
      defaultDocImage: localStorage.getItem('defaultDocImage'),
      defaultPdfImage: localStorage.getItem('defaultPdfImage'),
      defaultXlsImage: localStorage.getItem('defaultXlsImage'),
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false);
    axios
      .get(this.viewUrlApi(this.module, this.$route.params.id), self.model)
      .then(function (response) {
        var feedData = response.data.data;
        self.feedbackMessage = feedData.message;
        self.medias = feedData.media;
      });
  },
  methods: {
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "feedbacks"});
    },
    viewPdf(id) {
      // let self = this;
      // let routeData = this.$router.resolve({path: `/viewer/pdf/${id}`});
      window.open(id, "_blank");
    },
    viewDoc(id) {
      // let self = this;
      // let routeData = this.$router.resolve({path: `/viewer/doc/${id}`});
      window.open(id, "_blank");
    },
    viewXls(id) {
      // let self = this;
      // let routeData = this.$router.resolve({path: `/viewer/xls/${id}`});
      window.open(id, "_blank");
    },
    open(url) {
      let self = this;
      self.viewImage = url;
      self.largeModal = true;
    },
    documentsIndex: function (index) {
      return index + 1;
    },
  },
};
</script>
